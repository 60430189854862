<template>
    <div class="space">
        loushu
    </div>
</template>
<script>
export default {
    name: 'manage-space',
    data () {
        return {}
    },
    methods: {
        
    },
    created() {
        
    },
}
</script>
<style lang='scss' scoped>

</style>